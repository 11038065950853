var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"disable-pagination":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.categories,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("ADIANTAMENTOS")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-menu',{attrs:{"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","icon":"","large":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiTools)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ferramentas")])])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.sendExportExcel()}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFileExcelOutline))]),_vm._v(" Exportar Excel ")],1)],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"rounded":"","outlined":"","large":"","color":"primary"},on:{"click":function($event){_vm.dialogFilter=true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilter))]),_vm._v("Filtros")],1),_c('v-spacer')],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.date))+" ")]}},{key:"item.checkin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.checkin))+" ")]}},{key:"item.checkout",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.checkout))+" ")]}},{key:"item.room",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.descriptionRoom(item.room))+" ")]}},{key:"item.advance_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.advance_value))+" ")]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.originDescription(item.origin))+" ")]}},{key:"no-data",fn:function(){return [_c('NoDataTable')]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando... ")]},proxy:true}])}),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.dialogFilter),callback:function ($$v) {_vm.dialogFilter=$$v},expression:"dialogFilter"}},[_c('v-card',[_c('BarTitleDialog',{attrs:{"title":"FILTROS"},on:{"close":function($event){_vm.dialogFilter = false}}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","type":"date","label":"Data Checkin Inicial"},model:{value:(_vm.filter.date_start),callback:function ($$v) {_vm.$set(_vm.filter, "date_start", $$v)},expression:"filter.date_start"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","type":"date","label":"Data Checkin Final"},model:{value:(_vm.filter.date_end),callback:function ($$v) {_vm.$set(_vm.filter, "date_end", $$v)},expression:"filter.date_end"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"text-left"},[_c('v-btn',{attrs:{"text":"","color":"error","x-large":""},on:{"click":function($event){return _vm.clearFilter()}}},[_vm._v("limpar filtros")])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"secondary","x-large":""},on:{"click":function($event){_vm.dialogFilter = false}}},[_vm._v("FECHAR")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","x-large":""},on:{"click":function($event){return _vm.confirmFilter()}}},[_vm._v("aplicar")])],1)],1)],1)],1)],1),_c('MsgDelete',{on:{"delete-yes":function($event){return _vm.deleteItemConfirm()}},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }