<template>
<div>
    <v-data-table :loading="loadingTable" disable-pagination disable-sort hide-default-footer :headers="headers" :items="categories" sort-by="calories" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>ADIANTAMENTOS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-menu :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    <v-icon>
                                        {{ icons.mdiTools }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Ferramentas</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="sendExportExcel()">
                            <v-list-item-title>
                                <v-icon>{{ icons.mdiFileExcelOutline  }}</v-icon> Exportar Excel
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn @click="dialogFilter=true" class="ml-2" rounded outlined large color="primary"><v-icon>{{icons.mdiFilter }}</v-icon>Filtros</v-btn>
                <v-spacer></v-spacer>

            </v-toolbar>
        </template>
        
        <template v-slot:item.date="{ item }">
            {{ formatDateTime(item.date) }}
        </template>
        <template v-slot:item.checkin="{ item }">
            {{ formatDateTime(item.checkin) }}
        </template>

        <template v-slot:item.checkout="{ item }">
            {{ formatDate(item.checkout) }}
        </template>

        <template v-slot:item.room="{ item }">
            {{ descriptionRoom(item.room) }}
        </template>
        <template v-slot:item.advance_value="{ item }">
            {{ formatMoney(item.advance_value) }}
        </template>
        <template v-slot:item.origin="{ item }">
            {{ originDescription(item.origin) }}
        </template>


        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>

    <!-- DIALOG FILTRO -->
     <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="filter.date_start" outlined type="date" label="Data Checkin Inicial"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="filter.date_end" outlined type="date" label="Data Checkin Final"></v-text-field>
            </v-col>
          </v-row>
         </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiOpenInNew,
  mdiTools,
  mdiFileExcelOutline,
  mdiFilter,
} from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    filter: {},
    dialogFilter: false,
    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      {
        text: 'DATA ADIANTAMENTO',
        align: 'start',
        value: 'date',
      },
      {
        text: 'RESERVA ID',
        align: 'center',
        value: 'reserve_id',
      },
      {
        text: 'QUARTO',
        value: 'room',
        align: 'center',
      },
      {
        text: 'ORIGEM',
        value: 'origin',
        align: 'center',
      },

      {
        text: 'CHECKIN',
        value: 'checkin',
      },
      {
        text: 'CHECKOUT',
        value: 'checkout',
      },
      {
        text: 'ADIANTAMENTO',
        value: 'advance_value',
        align: 'end',
      },
      // {
      //   text: 'DIÁRIA',
      //   value: 'diary',
      // },

      // {
      //     text: '',
      //     align: 'end',
      //     value: 'actions',
      //     sortable: false,
      // },
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },

    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
    },
  }),

  computed: {
    newTab() {
      let url_atual = window.location.href
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('advance_payment', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true
      this.getAll(this.filter)
        .then(response => {
          this.categories = response.data
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    clearFilter() {
      this.filter = {}
    },
    sendExportExcel() {
      this.exportExcel(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {})
    },
    descriptionRoom(room) {
      if (room) {
        return room.description
      } else {
        return ''
      }
    },
    originDescription(origin) {
      if (origin == 'reserve') {
        return 'RESERVA'
      } else if (origin == 'checkin') {
        return 'CHECKIN'
      }
    },
    // roomDescription(item) {
    //   console.log(item.room)
    //   let rooms_descrition = []
    //   if (item.room != null) {
    //     item.room.forEach(room => {
    //       rooms_descrition.push(room.description)
    //     })
    //     return rooms_descrition.join()
    //   }
    //   return ''
    // },
    qtdRoom(item) {
      if (item.room.length > 0) {
        return item.room.length
      } else if (item.qtd_room) {
        return item.qtd_room
      } else {
        return 0
      }
    },

    qtdRoomTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(this.qtdRoom(item))
      }, 0)
    },

    qtdGuestTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(item.guest.length + item.guest_unknown.length)
      }, 0)
    },
    diaryTotal() {
      return this.categories.reduce((acc, item) => {
        return acc + parseFloat(item.extraordinary_tariff[0].value)
      }, 0)
    },
    diaryMedia() {
      const diaryTotal = this.diaryTotal()

      if (diaryTotal > 0) {
        return diaryTotal / this.qtdRoomTotal()
      }

      return 0
    },
    firstGuest(item) {
      if (item.guest.length > 0) {
        return item.guest[0].name
      } else if (item.guest_unknown.length > 0) {
        return item.guest_unknown[0].name
      }
      return ''
    },
    allGuests(item) {
      let guests = []
      item.guest.forEach(guest => {
        guests.push(guest.name)
      })

      item.guest_unknown.forEach(guest => {
        guests.push(guest.name)
      })

      return guests.join()
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'UTC',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    countReserves() {
      return this.categories.length
    },
  },
}
</script>
